<template>
    <div class="downLoadWrap">
        <el-tooltip class="item" effect="dark" :content="tipContent"  placement="top-start">
             <el-button type="text" class="el-icon-download" @click="down" v-loading="process" :disabled="process"></el-button>
        </el-tooltip>
        <!-- <el-button type="text" v-if="process">
            <el-progress type="circle" :percentage="percentage"></el-progress>
        </el-button> -->

        
    </div>
</template>
<script>
    import {asyncReportGen,asyncReportQuery,asyncReportDownload} from '@/api/adManagement'
    export default {
        props:{
            downLoadParams:{
                type:Object,
                default:(()=>{})
            },
            tipContent:{
                type:String,
                default:()=>"下载报告"
            }
        },
        data() {
            return {
                process:false,
                timer:null,
                percentage:0,
                flag:true
            }
        },
        methods:{
            down(){
                this.process = true;
                asyncReportGen(this.downLoadParams).then(res=>{
                    // console.log(res);
                    if(res.code == 0){
                        // this.process = true;
                        // this.getProcess(res.data.id)
                        this.processDownload(res.data.id);
                    }else{
                        this.processFailed( '下载失败')
                    }
                })
                // this.process = true;
            },
            processDownload(id) {
                asyncReportQuery({reportId: id}).then(res => {
                    if (res.code == 0) {
                        this.percentage = res.data.async_percent_completion;
                        if (res.data.async_status.key == 'Job Completed') {
                            // 完成，去下载
                            this.downLoad(id, res.data.download_url)
                        } else if (res.data.async_status.key == 'Job Failed') {
                            this.processFailed('任务失败。考虑重新访问查询，并重试。')
                        } else if (res.data.async_status.key == 'Job Skipped') {
                            this.processFailed( '任务已过期并跳过。请重新提交任务，并重试。');
                        }else{
                            setTimeout(()=>{this.processDownload(id)},5000);
                        }
                    }else{
                        this.processFailed( '下载失败')
                    }
                })
            },
            processFailed(msg){
                this.process=false;
                this.$message({type: 'warning', message: msg});
            },
            getProcess(id){
                this.timer = setInterval( () => {
                    if(!this.flag){
                        return false
                    }
                    this.flag = false;
                    asyncReportQuery({reportId:id}).then(res => {
                        this.flag = true;
                        if(res.code == 0) {
                            this.percentage = res.data.async_percent_completion;
                            if(res.data.async_status.key == 'Job Completed'){
                                // 完成，去下载
                                this.downLoad(id,res.data.download_url)
                            }
                            if(res.data.async_status.key == 'Job Failed') {
                                this.stopInterVal({type:'warning',message:'任务失败。考虑重新访问查询，并重试。'})
                            }
                            if(res.data.async_status.key == 'Job Skipped') {
                                this.stopInterVal({type:'warning',message:'任务已过期并跳过。请重新提交任务，并重试。'})
                            }
                        }
                    })
                },2000)
            },
            stopInterVal(tips){
                this.$message({
                    type:tips.type,
                    message:tips.message
                })
                clearInterval(this.timer);
            },
            downLoad(id,url){
                this.process = false;
                this.stopInterVal({type:'success',message:'下载成功。'})
                // window.open(url, '_blank');
                window.location.href = url;
            }
        }
    }
</script>
<style lang="scss">
    .downLoadWrap{
        font-weight: bold;
        .el-loading-spinner{
            margin-top: -10px;
        }
        .el-loading-spinner .circular {
            width: 20px;
            height: 20px;
        }
    }
    
</style>